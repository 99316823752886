import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=2ca6d3ad&scoped=true"
import script from "./confirm.vue?vue&type=script&lang=ts"
export * from "./confirm.vue?vue&type=script&lang=ts"
import style0 from "./confirm.vue?vue&type=style&index=0&id=2ca6d3ad&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca6d3ad",
  null
  
)

export default component.exports