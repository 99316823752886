<template>
  <v-row class="d-flex flex-column" no-gutters>
    <site-header :icon-link="false" />

    <v-main v-if="loading">
      <v-container fill-height>
        <v-layout align-center justify-center wrap>
          <v-flex>
            <v-layout fill-height justify-center align-center>
              <v-progress-circular :size="70" color="text" indeterminate />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <layout-3
      v-else-if="verificationFailed"
      :title="$t('signup.confirm.error.title')"
      :subtitle="$t('signup.confirm.error.subtitle')"
      :image="{ src: 'email-link-expired', width: 340, height: 440 }"
      hide-image-on-mobile
    >
      <v-btn :nuxt="true" :to="ctaRoute" class="ma-0" color="info" large>
        {{ $t('signup.confirm.error.cta') }}
      </v-btn>
    </layout-3>
    <layout-3
      v-else
      :title="$t('signup.fairflat.confirm.title')"
      :subtitle="$t('signup.fairflat.confirm.text')"
      :image="{ src: 'welcome', width: 450, height: 389 }"
      hide-image-on-mobile
    >
      <v-btn data-cy="signupConfirmBtn" :nuxt="true" :to="ctaRoute" class="ma-0" color="info" x-large>
        {{ $t('signup.confirm.complete') }}
      </v-btn>
    </layout-3>

    <site-footer />
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'

import Layout3 from '~/components/layouts/Layout3.vue'
import SiteFooter from '~/components/SiteFooter.vue'
import SiteHeader from '~/components/SiteHeader.vue'

export default Vue.extend({
  components: {
    Layout3,
    SiteFooter,
    SiteHeader,
  },
  data() {
    return {
      loading: true,
      ctaRoute: { name: '' },
      verificationFailed: false,
    }
  },
  head() {
    return {
      title: 'Sign Up Email Confirm',
    }
  },
  async created() {
    // we saw some issues with undefined tokens at end of signup which was most
    // likely because we set the value based on query parameter s. it might
    // have happened that this parameter was only read after the user clicked
    // on continue, changed the route and therefore had no longer access to s.
    // we now store it in a variable and won't display anything until
    // everything is properly loaded.
    const token = this.$route.query.s
    localStorage.setItem('signup-token', token.toString())

    // verify specified token
    try {
      const result = (await this.$store.dispatch('verifyConfirmationToken', {
        token,
      })) as { email: string; flow: string }
      // token was already stored before checking its validity
      localStorage.setItem('signup-email', result.email)
      if (result.flow === 'business') localStorage.setItem('business-invite', 'true')
      this.ctaRoute = { name: 'signup-steps' }
      this.loading = false
    } catch (error: unknown) {
      const e = error as { response: { status: number } }
      // there was a problem, most likely the token timed out
      // or an invalid token was sent, so display an error
      // remove previously set token in error case
      localStorage.removeItem('signup-token')
      if (e.response.status === 410) {
        void this.$router.push({ name: 'expired' })
      } else {
        this.verificationFailed = true
        this.ctaRoute = { name: 'signup' }
      }
      this.loading = false
    }
  },
})
</script>

<style scoped lang="sass">
.confirm
  +add-lateral-site-padding
  +add-focussed-form-layout
  +add-vertical-main-padding
  +center-content
</style>
